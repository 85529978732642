<template>
  <div
    class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-1/2 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col sm:w-full md:w-full lg:w-full d-theme-dark-bg">
              <div class="px-8 pt-8 pb-8">
                <div class="vx-card__title mb-4 text-center">
                  <h1 class="mb-4">Xác thực tài khoản</h1>
                </div>
                <div class="pt-5">
                  <div class="flex flex-wrap my-5" style = "justify-content: center">
                    <!-- <vs-checkbox class="mb-3"></vs-checkbox> -->
                    <div>Vui lòng xác nhận email để hoàn tất đăng ký</div>

                  </div>
                </div>
                <div class="pt-5">
                  <div class="flex flex-wrap my-5" style = "justify-content: center">
                    <!-- <vs-checkbox class="mb-3"></vs-checkbox> -->
                    <div>Nếu bạn không nhận được email. </div>
                    <router-link v-on:click.native="resendEmail" to = "" >&nbsp;Click vào đây để gửi lại</router-link>

                  </div>
                </div>
                <div class="pt-5">
                  <div class="flex flex-wrap my-5" style = "justify-content: center">
                    <!-- <vs-checkbox class="mb-3"></vs-checkbox> -->
                    <div>Nếu bạn đã có tài khoản, click vào đây để </div>
                    <router-link to="/pages/login">&nbsp;Đăng nhập</router-link>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
  import vSelect from 'vue-select'
  import province from '@/assets/utils/province.js';
  import Datepicker from 'vuejs-datepicker';
  export default {
    data() {
      return {
        registerSuccess: 0,
        genderOptions: [
          {value: 0, label: 'Khác'},
          {value: 1, label: 'Nữ'},
          {value: 2, label: 'Nam'}
        ],
        cityOptions: province.getProvince(),
        districtOptions: province.getDistrict(),
        subDistrictOptions: province.getSubDistrict(),
        user: {
          gender: null,
          city: null,
          district: null,
          subDistrict: null,
          address: null,
          houseNumber: null,
          password: "",
          rePassword: ""
        },
      };
    },
    components: {
      vSelect,
      Datepicker
    },
    computed: {
      validateForm() {
        return !this.errors.any();
      },
      genderModel: {
        get() {
          let obj = this.genderOptions.find(x => x.value === this.user.gender);
          if (!obj) {
            return null;
          }
          return {
            label: obj.label,
            value: this.user.gender
          }
        },
        set(obj) {
          this.user.gender = obj.value;
        }
      },
      province: {
        get() {
          let obj = this.cityOptions.find(x => x.value === this.user.city);
          if (!obj) {
            return null;
          }
          return {
            label: obj.label,
            value: this.user.city
          }
        },
        set(obj) {
          this.user.city = obj.value;
          this.districtOptions = province.getDistrict(obj.value);
          this.user.district = this.districtOptions[0].value;
          this.subDistrictOptions = province.getSubDistrict(this.user.city, this.user.district);
          this.user.subDistrict = this.subDistrictOptions[0].value;
        }
      },
      district: {
        get() {
          let obj = this.districtOptions.find(x => x.value === this.user.district);
          if (!obj) {
            return null;
          }
          return {
            label: obj.label,
            value: this.user.district
          }
        },
        set(obj) {
          this.user.district = obj.value;
          this.subDistrictOptions = province.getSubDistrict(this.user.city, obj.value);
          this.user.subDistrict = this.subDistrictOptions[0].value;
        }
      },
      subDistrict: {
        get() {
          let obj = this.subDistrictOptions.find(x => x.value === this.user.subDistrict);
          if (!obj) {
            return null;
          }
          return {
            label: obj.label,
            value: this.user.subDistrict
          }
        },
        set(obj) {
          this.user.subDistrict = obj.value
        }
      },
      fullAddressModel: {
        get() {
          let houseNumber = `${this.user.houseNumber ? this.user.houseNumber + ', ' : ''}`
          let subDistrict = `${this.subDistrict && this.subDistrict.label ? this.subDistrict.label + ', ' : ''}`
          let district = `${this.district && this.district.label ? this.district.label + ", " : ''}`
          let province = `${this.province && this.province.label ? this.province.label: ''}`;
          /* eslint-disable */
          this.user.fullAddress = houseNumber + subDistrict + district + province;
          return this.user.fullAddress;
        },
      }
    },
    created() {
      this.user.domain = location.protocol + '//' + location.host
      this.user.email = this.$route.query.email
      this.resendEmail()
    },
    methods: {
        getLabelString(object) {
            return (object && object.label);
        },
        changeGender () {
            this.$validator.validateAll()
        },
        async registerUser(evt) {
            evt.preventDefault();
            if (!this.validateForm) return;
            const result = await this.$validator.validateAll();
            if (!result) return;
            this.$vs.loading();
            this.user.domain = location.protocol + '//' + location.host
            this.$oauth.post('/registration/email', this.user).then((response) => {
                this.registerSuccess = 1;
                this.$vs.loading.close();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
                });
            });
        },
        resendEmail () {
            this.$vs.loading();
            this.$oauth.post('/registration/send-email-active', this.user).then((response) => {
                this.$vs.loading.close();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
                });
            });
        }
    }
  };
</script>
